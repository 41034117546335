f<template>
  <v-container fluid class="ma-0 pa-0" :class="{'light-background': $vuetify.breakpoint.xsOnly, 'white': $vuetify.breakpoint.smAndUp}">
    <v-layout row wrap>
      <v-flex xs12>
        <v-card color="light-background" flat tile>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12 class="max-width-1400 full-width ma-auto">
                <v-layout row wrap>
                  <v-flex d-flex mb-4>
                    <h3 class="graphik-bold font-20 primary--text mb-0">{{ $ml.get('profile_title') }}</h3>

                    <v-spacer></v-spacer>

                    <v-btn 
                      color="primary"
                      flat
                      :loading="g_isLoading"
                      class="graphik-medium normalcase ma-0 border-radius-5 max-width-150"
                      @click="edit"
                    >{{ !is_editing ? $ml.get('profile_edit') : $ml.get('general_cancel') }}</v-btn>
                  </v-flex>
                </v-layout>

                <v-form v-model="valid_user">
                  <v-container fluid pa-0 ma-0 grid-list-xl>
                    <v-layout row wrap>
                      <v-flex xs12 sm6>
                        <p class="graphik-medium font-17 mb-2 dark-grey--text">{{ $ml.get('fields_name') }}</p>

                        <v-text-field
                          v-model="user.first_name"
                          solo
                          :clearable="is_editing"
                          required
                          :disabled="g_isLoading || !is_editing"
                          :rules="g_required_rules"
                          class="hidden-shadow small-input"
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm6>
                        <p class="graphik-medium font-17 mb-2 dark-grey--text">{{ $ml.get('fields_last_name') }}</p>

                        <v-text-field
                          v-model="user.last_name"
                          solo
                          :clearable="is_editing"
                          required
                          :disabled="g_isLoading || !is_editing"
                          :rules="g_required_rules"
                          class="hidden-shadow small-input"
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm6>
                        <p class="graphik-medium font-17 mb-2 dark-grey--text">{{ $ml.get('fields_second_last_name') }}</p>

                        <v-text-field
                          v-model="user.second_last_name"
                          solo
                          :clearable="is_editing"
                          required
                          :disabled="g_isLoading || !is_editing"
                          :rules="g_required_rules"
                          class="hidden-shadow small-input"
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm6>
                        <p class="graphik-medium font-17 mb-2 dark-grey--text">{{ $ml.get('fields_email') }}</p>

                        <v-text-field
                          v-model="user.email"
                          solo
                          disabled
                          required
                          :rules="g_email_rules"
                          class="hidden-shadow small-input"
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm6>
                        <p class="graphik-medium font-17 mb-2 dark-grey--text">{{ $ml.get('fields_cellphone') }}</p>

                        <v-text-field
                          v-model="user.cellphone"
                          solo
                          :clearable="is_editing"
                          required
                          :disabled="g_isLoading || !is_editing"
                          mask="phone"
                          :rules="g_phone_rules"
                          class="hidden-shadow small-input"
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm6>
                        <p class="graphik-medium font-17 mb-2 dark-grey--text">{{ $ml.get('fields_gender') }}</p>

                        <v-autocomplete
                          v-model="user.gender"
                          :items="options.genders"
                          solo
                          :clearable="is_editing"
                          :disabled="g_isLoading || !is_editing"
                          required
                          :rules="g_required_rules"
                          :no-data-text="$ml.get('general_no_results')"
                          class="hidden-shadow small-input"
                        ></v-autocomplete>
                      </v-flex>

                      <v-flex xs12 sm6>
                        <p class="graphik-medium font-17 mb-2 dark-grey--text text-xs-left">{{ $ml.get('fields_birthday') }}</p>

                        <v-menu
                          :close-on-content-click="false"
                          v-model="birthdate_menu"
                          :nudge-right="40"
                          lazy
                          transition="scale-transition"
                          offset-y
                          full-width
                          max-width="290px"
                          min-width="290px"
                        >
                          <v-text-field
                            solo
                            slot="activator"
                            v-model="birthdate_formatted"
                            persistent-hint
                            readonly
                            append-icon="event"
                            required
                            :disabled="g_isLoading || !is_editing"
                            :rules="g_required_rules"
                            class="hidden-shadow small-input"
                          ></v-text-field>

                          <v-date-picker
                            ref="birthdate_picker"
                            v-model="user.birthday"
                            :disabled="g_isLoading || !is_editing"
                            no-title
                            locale="es-ES"
                            :max="adult_only"
                            min="1900-01-01"
                            @input="birthdate_menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-form>
              </v-flex>
            </v-layout>
          </v-card-text>

          <v-card-actions class="pa-3" v-if="is_editing">
            <v-spacer></v-spacer>
            <v-btn 
              color="primary"
              depressed
              :disabled="!valid_user"
              :loading="g_isLoading"
              class="normalcase graphik-bold-italic border-radius-5"
              @click="save"
            >{{ $ml.get('general_save') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>

    <message-dialog :dialog="g_dialog"></message-dialog>
    <loading-dialog :isLoading="g_isLoading"></loading-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'ProfileUser',
  metaInfo: {
    title: process.env.VUE_APP_METATAGS_TITLE_PROFILE_USER,
    meta: [
      {
        vmid: 'og:title',
        property: 'og:title',
        content: process.env.VUE_APP_METATAGS_TITLE_PROFILE_USER
      },
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: process.env.VUE_APP_METATAGS_TITLE_PROFILE_USER
      }
    ]
  },
  watch: {
    g_user (v) {
      this.user = {...v}
    },
    birthdate_menu (value) {
      value && this.$nextTick(() => (this.$refs.birthdate_picker.activePicker = 'YEAR'))
    }
  },
  computed: {
    birthdate_formatted: {
      get () {
        if (!this.user.birthday) return null

        const [year, month, day] = this.user.birthday.split('-')
        return `${day}/${month}/${year}`
      },
      set () {
        this.user.birthday = this.user.birthday
      }
    },
    adult_only () {
      return this.$moment().subtract(18, 'years').format('YYYY-MM-DD')
    }
  },
  data () {
    return {
      valid_user: false,
      is_editing: false,
      user: {},
      birthdate_menu: false,
      options: {
        genders: [
          {
            text: this.$ml.get('fields_options_gender_male'),
            value: 'H',
            icon: 'fas fa-male'
          },
          {
            text: this.$ml.get('fields_options_gender_female'),
            value: 'M',
            icon: 'fas fa-female'
          }
        ]
      }
    }
  },
  methods: {
    edit () {
      this.is_editing = !this.is_editing
      this.user = {...this.g_user}
    },
    save () {
      this.g_isLoading = true

      this.$api.editUser(
        this.user,
        () => {
          this.$analytics('profile_edit_user')
          this.$authGetUser()
          this.is_editing = false
          this.g_isLoading = false
        }, response => this.$errorHandling(response).then(() => {
          this.save()
        }, () => {})
      )
    }
  },
  mounted () {
    this.$authGetUser()
  }
}
</script>

<style scoped>

</style>
